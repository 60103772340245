<template>
  <SingleModelLayout
    :model.sync="product"
    :loading="loading"
    :uploading="uploading"
    :route-back="{
      name: `${product.is_part ? 'parts' : 'products'}.index`
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        :edit.sync="edit"
        :view="!product.is_part ? `${frontendUrl}/product/${product.slug}` : null"
        class="mb-4"
        @edit="startEdit"
        @save="saveProduct"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="product.name"
            :value="product.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ product.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-if="product.extra_data && product.extra_data.categories && product.extra_data.categories.default"
        :value="product.images.featured"
        :edit="edit"
        :model="product"
        class="mb-4"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Featured Image"
        @update="syncImage($event, 'featured_image')"
        @discard="removeImage"
      />
      <vue-data
        v-if="product.extra_data && product.extra_data.categories && product.extra_data.categories.elite"
        :value="product.images.featured_elite"
        :edit="edit"
        :model="product"
        class="mb-4"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Featured Image (SG Series)"
        @update="syncImage($event, 'featured_image_elite')"
        @discard="removeImage"
      />
      <vue-data
        v-model="product.sku"
        :value="product.sku"
        :edit="edit"
        class="mb-2"
        label="SKU"
      />
      <vue-data
        v-model="localePrice"
        :value="localePrice"
        :edit="edit"
        :prepend="$route.params.localeFull ? $route.params.localeFull.currencySymbol : '£'"
        :append="$route.params.localeFull ? $route.params.localeFull.currency : 'GBP'"
        class="mb-2"
        type="number"
        step="0.01"
        label="Price"
      />
      <vue-data
        v-model="localePriceDiscounted"
        :value="localePriceDiscounted"
        :edit="edit"
        :prepend="$route.params.localeFull ? $route.params.localeFull.currencySymbol : '£'"
        :append="$route.params.localeFull ? $route.params.localeFull.currency : 'GBP'"
        class="mb-2"
        type="number"
        step="0.01"
        label="Discounted Price"
      />
      <vue-data
        v-model="localeDescription"
        :value="localeDescription"
        :edit="edit"
        class="mb-2"
        type="markdown"
        label="Description"
        rows="10"
      />
      <template v-if="product.is_part">
        <vue-data
          v-model="product.extra_data.assembly_key"
          :value="product.extra_data.assembly_key"
          :edit="edit"
          :options="partBuildSetFields"
          :dropdownLabel="(option) => {
            return `${option.name} (${option.key})`
          }"
          valueKey="key"
          class="mb-2"
          type="select"
          label="Assembly Key"
        />
        <vue-data
          v-model="product.extra_data.short_name"
          :value="product.extra_data.short_name"
          :edit="edit"
          class="mb-2"
          label="Short Name"
        />
        <vue-data
          v-model="product.extra_data.always_included"
          :value="product.extra_data.always_included"
          :edit="edit"
          type="checkbox"
          class="mb-2"
          label="Always Included?"
        />
      </template>
      <template v-else>
        <vue-data
          v-model="product.assembly_set"
          :value="product.assembly_set"
          :edit="edit"
          :options="sets"
          class="mb-2"
          type="select"
          labelKey="name"
          label="Assembly Set"
        />
        <vue-data
          v-model="product.extra_data.default_game"
          :value="product.extra_data.default_game"
          :edit="edit"
          :options="games"
          class="mb-2"
          labelKey="label"
          type="select"
          name="defaut_game"
          prop="default_game"
          label="Default Game"
        />
        <vue-data
          v-model="product.extra_data.default_resolution"
          :value="product.extra_data.default_resolution"
          :edit="edit"
          :options="[
            {
              label: 'Low',
              value: 'low'
            },
            {
              label: 'Medium',
              value: 'med'
            },
            {
              label: 'High',
              label: 'high'
            },
            {
              label: 'Ultra',
              value: 'max'
            }
          ]"
          class="mb-2"
          labelKey="label"
          valueKey="value"
          type="select"
          name="defaut_resolution"
          prop="default_resolution"
          label="Default Resolution"
        />
        <vue-data
          v-model="product.extra_data.default_detail"
          :value="product.extra_data.default_detail"
          :edit="edit"
          :options="[
            {
              label: '1080p',
              value: '1080p'
            },
            {
              label: '1440p',
              value: '1440p'
            },
            {
              label: '4K',
              label: '4k'
            }
          ]"
          class="mb-2"
          labelKey="label"
          valueKey="value"
          type="select"
          name="defaut_detail"
          prop="default_detail"
          label="Default Detail"
        />
      </template>
      <vue-data
        v-model="product.live_at"
        :value="product.live_at"
        :edit="edit"
        class="mb-2"
        type="datetime"
        name="live_at"
        prop="live_at"
        label="Publish Date"
      />
      <vue-data
        v-model="product.extra_data.visible"
        :value="product.extra_data.visible"
        :edit="edit"
        type="checkbox"
        class="mb-2"
        label="In Stock?"
        :description="`Unchecked if the ${product.is_part ? 'part' : 'product'} is out of stock or needs to be hidden on the site. ${product.is_part ? 'If this part is included in a prebuilt, the prebuilt will force the user to select another part.' : ''}`"
      />
    </template>
    <template #main>
      <LocaleSwitcher
        :edit="edit"
        @switch="getModel"
      />
      <div class="flex flex-col space-y-4">
        <vue-card border>
          <label class="font-medium text-sm mb-1">
            Categories
          </label>
          <ul>
            <li v-for="(value, index) in product.extra_data.categories" :key="index">
              <vue-checkbox
                v-if="edit"
                v-model="product.extra_data.categories[index]"
                :label="getCategoryLabel(index)"
              />
              <data
                v-else
                :data="value"
                class="flex items-center space-x-1 py-1"
              >
                <font-awesome-icon
                  :icon="['fas', value ? 'check-circle' : 'times-circle']"
                  :class="{
                    'text-green-700': value,
                    'text-red-700': !value
                  }"
                  size="lg"
                  fixed-width
                />
                <span>{{ getCategoryLabel(index) }}</span>
              </data>
            </li>
          </ul>
        </vue-card>
        <template v-if="product.parts && !product.is_part">
          <PartsAssigned
            ref="partsAssigned"
            :model.sync="product"
            :edit="edit"
          />
        </template>
      </div>
    </template>
    <template v-if="product.is_part" #right>
      <header class="group mb-4">
        <h2 class="text-2xl mb-2">
          Compatability
        </h2>
        <p class="text-sm text-gray-600 group-hover:text-black transition-colors mb-1">
          These compatability fields are used for matching up certain products to other products, such as the Motherboard with compatible CPU sockets.
        </p>
        <p class="text-sm text-gray-600 group-hover:text-black transition-colors mb-1">
          If this part does not use one of these values, leave them empty.
        </p>
      </header>
      <vue-data
        v-if="product.extra_data.groups"
        v-model="product.extra_data.groups.brand"
        :value="product.extra_data.groups.brand"
        :edit="edit"
        placeholder="Intel"
        class="mb-2"
        label="Brand"
      />
      <vue-data
        v-if="product.extra_data.groups && product.extra_data.assembly_key && ['gpu', 'psu'].includes(product.extra_data.assembly_key)"
        v-model="product.extra_data.groups.wattage"
        :value="product.extra_data.groups.wattage"
        :edit="edit"
        placeholder="500W"
        class="mb-2"
        :label="product.extra_data.assembly_key && product.extra_data.assembly_key === 'gpu' ? 'Wattage Required' : 'Wattage Output'"
      />
      <vue-data
        v-if="product.extra_data.groups && product.extra_data.assembly_key && ['mobo', 'ram'].includes(product.extra_data.assembly_key)"
        v-model="product.extra_data.groups.ram_slots"
        :value="product.extra_data.groups.ram_slots"
        :edit="edit"
        type="number"
        min="0"
        placeholder="2"
        class="mb-2"
        :label="product.extra_data.assembly_key && product.extra_data.assembly_key === 'mobo' ? 'Maximum RAM Slots' : 'RAM Slots'"
      />
      <vue-data
        v-if="product.extra_data.groups && product.extra_data.assembly_key && ['mobo', 'cpu'].includes(product.extra_data.assembly_key)"
        v-model="product.extra_data.groups.cpu_socket"
        :value="product.extra_data.groups.cpu_socket"
        :edit="edit"
        placeholder="LGA1200"
        class="mb-2"
        :label="product.extra_data.assembly_key && product.extra_data.assembly_key === 'mobo' ? 'Accepted CPU Socket' : 'CPU Socket'"
      />
      <vue-data
        v-if="product.extra_data.groups && product.extra_data.assembly_key && ['mobo', 'ssd'].includes(product.extra_data.assembly_key)"
        v-model="product.extra_data.groups.onboard_storage"
        :value="product.extra_data.groups.onboard_storage"
        :edit="edit"
        placeholder="M.2"
        class="mb-2"
        :label="product.extra_data.assembly_key && product.extra_data.assembly_key === 'mobo' ? 'SSD Storage Compatability' : 'SSD Storage Type'"
      />
      <vue-data
        v-if="product.extra_data.groups && product.extra_data.assembly_key && ['cpu', 'gpu'].includes(product.extra_data.assembly_key)"
        v-model="product.extra_data.groups.integrated_gpu"
        :value="product.extra_data.groups.integrated_gpu"
        :edit="edit"
        class="mb-2"
        type="checkbox"
        :label="product.extra_data.assembly_key && product.extra_data.assembly_key === 'cpu' ? 'Contains iGPU?' : 'Is iGPU part?'"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { findIndex, pullAt, map, cloneDeep, merge } from 'lodash'
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'
import PartsAssigned from '@/components/PartsAssigned'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import HasCategoryLabels from '@/mixins/HasCategoryLabels'

export default {
  name: 'ProductsSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard,
    PartsAssigned,
    LocaleSwitcher
  },

  mixins: [
    HasCategoryLabels
  ],

  data () {
    return {
      loading: false,
      uploading: false,
      edit: false,
      product: false,
      productOld: false,
      files: [],
      sets: [],
      games: [],
      route: {
        apiEndpoint: 'products',
        mediaEndpoint: 'product',
        index: 'products.index'
      },
      localePrice: null,
      localePriceDiscounted: null,
      localeDescription: null,
      partSections: {},
      defaultBuildSet: {},
      defaultModelData: {
        extra_data: {
          groups: {
            brand: null,
            wattage: null,
            ram_slots: null,
            cpu_socket: null,
            onboard_storage: null,
            integrated_gpu: false
          },
          categories: {
            elite: false,
            default: true
          },
          visible: true,
          default_game: null,
          unit_cost: 0,
          short_name: null,
          assembly_key: null,
          component_name: '',
          always_included: false
        }
      }
    }
  },

  created () {
    this.getModel()
    this.getSets()
    this.getBuildSet()
    this.getGames()
  },

  computed: {
    partAssemblies () {
      if (!this.product.assembly_set) {
        return []
      }
      return this.product.assembly_set.fields
    },

    partBuildSetFields () {
      return this.defaultBuildSet.fields
    },

    frontendUrl () {
      return process.env.VUE_APP_FRONTEND_URL
    }
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          locale: this.$route.params.locale,
          currency: this.$route.params.currency,
          withImages: true,
          withPivot: true,
          with: ['parts', 'assemblySet', 'assemblySet.assemblies']
        }
      })
        .then((res) => {
          const product = res.data
          this.product = !product.extra_data ? merge(product, this.defaultModelData) : merge(this.defaultModelData, product)

          if (typeof this.product.locale_prices !== 'undefined' && typeof this.product?.locale_prices[this.$route.params.locale || 'en-GB'] !== 'undefined') {
            this.localePrice = this.product.locale_prices[this.$route.params.locale || 'en-GB']?.price
            this.localePriceDiscounted = this.product.locale_prices[this.$route.params.locale || 'en-GB']?.discounted_price
          }

          if (typeof this.product.locale_data !== 'undefined' && typeof this.product.locale_data[this.$route.params.locale || 'en-GB'] !== 'undefined') {
            this.localeDescription = this.product?.locale_data[this.$route.params.locale || 'en-GB']?.description
          }
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getBuildSet () {
      this.loading = true
      this.$api.get('public/sets/default-build-set', {
        params: {
          withPivot: true,
          with: ['assemblies']
        }
      })
        .then((res) => {
          this.defaultBuildSet = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getGames () {
      this.loading = true
      this.$api.get('metadata', {
        params: {
          type: 'game'
        }
      })
        .then((res) => {
          this.games = map(res.data, (game) => {
            return {
              label: game.value.name,
              value: game.id
            }
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: `${this.product.is_part ? 'parts' : 'products'}.index`
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    removePart (id) {
      pullAt(this.product.parts, findIndex(this.product.parts, { id }))
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage () {
      this.files = false
    },

    getSets () {
      this.loading = true
      this.$api.get('sets')
        .then(({ data: res }) => {
          this.sets = res
        })
    },

    saveProduct () {
      this.loading = true
      this.saveFiles()

      this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, merge(
        this.product,
        {
          locale: this.$route.params.locale,
          currency: this.$route.params.currency,
          price: this.localePrice !== null ? this.localePrice : this.product.price,
          discounted_price: this.localePriceDiscounted !== null ? this.localePriceDiscounted : this.product.discounted_price,
          extra_data: {
            description: this.localeDescription !== null ? this.localeDescription : this.product.extra_data.description
          }
        }
      ))
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveFiles () {
      if (this.files && this.files.length > 0) {
        this.uploading = true
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('model', 'Product')
          formData.append('collection', file.collection)
          this.$api.post(`media/upload/${this.route.mediaEndpoint}/${this.$route.params.model}`, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .then(() => {
              file.uploaded = true
              this.uploading = false
            })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
    },

    startEdit () {
      this.productOld = cloneDeep(this.product)
    },

    discardEdit () {
      this.product = this.productOld
      this.productOld = false
      this.edit = false
    }
  }
}
</script>
<style>
.single-product-part-list {
  max-height: 250px;
  overflow-y: auto;
}
</style>

<template>
  <div class="flex flex-col bg-gray-200 rounded px-2 py-2 mb-4">
    <div class="flex items-center space-x-1">
      <template v-if="!edit || disableEditCheck">
        <vue-button
          v-for="locale in locales"
          :key="locale.iso"
          :class="{
            'bg-primary text-white hover:bg-primary-dark': locale.iso === currentLocale.iso,
            'bg-transparent text-primary hover:bg-primary hover:text-primary-contrast': locale.iso !== currentLocale.iso
          }"
          custom
          @click="switchLocale(locale.iso)"
        >
          {{ locale.name }}
        </vue-button>
      </template>
      <template v-else-if="!disableEditCheck">
        <span class="flex items-center text-white bg-primary rounded border-2 border-transparent px-3 py-2">{{ currentLocale.name }}</span>
        <span class="px-3 py-2">Finish editing and save to switch languages</span>
      </template>
    </div>
    <div v-if="disableEditCheck" class="block px-3.5 py-2 mt-1">
      Ensure you save your changes before switching languages
    </div>
  </div>
</template>
<script>
import UsesLocales from '@/mixins/UsesLocales'

export default {
  name: 'LocaleSwitcher',

  mixins: [
    UsesLocales
  ],

  props: {
    edit: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    disableEditCheck: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  }
}
</script>

import { find } from 'lodash'

export default {
  data () {
    return {
      currentLocale: {},
      locales: [
        {
          name: 'English',
          code: 'en',
          iso: 'en-GB',
          currency: 'GBP',
          currencySymbol: '£',
          default: true
        },
        {
          name: 'French',
          code: 'fr',
          iso: 'fr-FR',
          currency: 'EUR',
          currencySymbol: '€'
        },
        {
          name: 'Italian',
          code: 'it',
          iso: 'it-IT',
          currency: 'EUR',
          currencySymbol: '€'
        },
        {
          name: 'Spanish',
          code: 'es',
          iso: 'es-ES',
          currency: 'EUR',
          currencySymbol: '€'
        },
        {
          name: 'German',
          code: 'de',
          iso: 'de-DE',
          currency: 'EUR',
          currencySymbol: '€'
        }
      ]
    }
  },

  mounted () {
    this.currentLocale = find(this.locales, { default: true })
    this.syncLocale(this.currentLocale)
  },

  methods: {
    switchLocale (locale) {
      this.edit = false
      this.currentLocale = find(this.locales, { iso: locale })
      this.syncLocale(this.currentLocale)
      this.$emit('switch', this.currentLocale.iso)
    },

    syncLocale (locale) {
      this.$route.params.locale = locale.iso
      this.$route.params.currency = locale.currency
      this.$route.params.localeFull = locale
    }
  }
}

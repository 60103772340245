 <template>
  <section class="mb-8">
    <div class="flex items-center justify-between mb-2">
      <h3 class="text-2xl">
        Parts Assigned
      </h3>
      <vue-button
        v-if="edit"
        :icon="['fas', 'plus-circle']"
        text
        @click="assignNewModels"
      />
    </div>
    <div v-if="fields && fields.length > 0" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 auto-rows-fr gap-2">
      <div v-for="field in fields" :key="field.id" class="flex flex-col">
        <vue-card border class="flex flex-col h-full px-3 mb-2">
          <header class="flex items-center justify-between mb-1">
            <h4 class="font-bold text-sm uppercase">
              {{ field.name }}
            </h4>
            <div class="relative flex">
              <vue-button
                v-if="edit"
                :icon="['fas', 'sort-alt']"
                text
                sm
                @click="toggleDropdown(field.key)"
              />
              <div
                v-if="edit && dropdown.includes(field.key)"
                class="absolute top-full right-0 flex flex-col bg-white shadow-lg rounded"
              >
                <span class="text-sm whitespace-nowrap px-3 py-1">
                  Auto sort group:
                </span>
                <vue-button
                  :icon="['fas', 'font']"
                  icon-size="sm"
                  text
                  @click="sortGroup(field.key, 'name')"
                >
                  Name
                </vue-button>
                <vue-button
                  :icon="['fas', 'pound-sign']"
                  icon-size="sm"
                  text
                  @click="sortGroup(field.key, 'price')"
                >
                  Price
                </vue-button>
              </div>
            </div>
          </header>
          <div class="single-product-part-list flex flex-col flex-grow text-sm space-y-1">
            <draggable
              v-if="partGroups && partGroups[field.key] && partGroups[field.key].length > 0"
              v-model="partGroups[field.key]"
              :disabled="!edit"
              handle=".handle"
              class="w-full space-y-1"
              @change="storeSortOrder(field.key, $event)"
            >
              <div v-for="(part, index) in partGroups[field.key]" :key="index" class="flex justify-between items-center bg-gray-200 rounded">
                <span v-if="edit" class="handle cursor-move px-2">
                  <font-awesome-icon :icon="['fas', 'arrows-alt-v']" fa-fw />
                </span>
                <div class="flex flex-grow items-center justify-between">
                  <span class="px-2 py-1">
                    {{ part.name }}
                  </span>
                  <span class="px-2 py-1">
                    £{{ (part.price ? part.price : 0).toFixed(2) }}
                  </span>
                </div>
                <vue-button
                  v-if="edit"
                  :icon="['fas', 'trash']"
                  error
                  text
                  sm
                  @click="removeModel(part.id)"
                />
              </div>
            </draggable>
            <div v-else class="opacity-50">
              No part assigned
            </div>
          </div>
        </vue-card>
      </div>
    </div>
    <vue-dialog :dialog.sync="form.modal">
      <template #title>
        Assign New Models
      </template>
      <div class="flex flex-col">
        <div class="flex mb-2">
          <vue-select
            v-model="form.field"
            :options="fields"
            labelKey="name"
            placeholder="Field"
            class="border-shade-dark"
            append
          />
          <vue-input
            v-model="form.search"
            placeholder="Search for parts"
            class="flex-grow border-shade-dark"
            prepend
            append
          />
          <vue-button
            sm
            append
            @click="searchModels"
          >
            Find
          </vue-button>
        </div>
        <div v-if="!form.loading && form.models.length > 0" class="flex flex-col flex-grow overflow-y-auto my-2">
          <div>
            <vue-checkbox-group
              v-model="form.new"
              :data="form.models"
              label-key="name"
              value-key="id"
            />
          </div>
        </div>
        <div v-else-if="!form.loading" class="my-2">
          No results found
        </div>
        <div v-if="form.loading" class="my-2">
          Loading...
        </div>
      </div>
      <template #action>
        <vue-button success class="justify-center w-full" @click="addModels">
          Add Models
        </vue-button>
      </template>
    </vue-dialog>
  </section>
</template>
<script>
import { filter, findIndex, keyBy, sortBy, map, mapValues, difference, cloneDeep, merge, compact } from 'lodash' // eslint-disable-line no-unused-vars
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },

  props: {
    model: {
      type: [Boolean, Object],
      required: false,
      default: () => { return null }
    },

    edit: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      fields: [],
      groups: {},
      dropdown: [],
      form: {
        loading: false,
        models: false,
        new: [],
        search: '',
        modal: false,
        field: false
      }
    }
  },

  computed: {
    partGroups () {
      return mapValues(keyBy(cloneDeep(this.fields), 'key'), (field) => {
        return sortBy(filter(cloneDeep(this.model.parts), { extra_data: { assembly_key: field.key } }), (item) => { return item.pivot ? item.pivot.order || 0 : 0 })
      })
    }
  },

  watch: {
    'form.field': function () {
      this.getModels()
    },

    'form.modal': function () {
      this.form.search = ''
      this.form.new = []
    }
  },

  mounted () {
    this.getFields()
  },

  methods: {
    assignNewModels () {
      this.form.search = ''
      this.getModels()
      this.form.modal = true
    },

    getModels () {
      this.form.loading = true
      this.$api.get('products', {
        params: {
          search: this.form.search,
          assembly_key: this.form.field.key
        }
      })
        .then((res) => {
          this.form.models = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.form.loading = false
        })
    },

    getFields () {
      this.form.loading = true
      this.$api.get('public/sets/default-build-set', {
        params: {
          with: ['assemblies']
        }
      })
        .then((res) => {
          this.fields = res.data.fields
          this.form.field = this.fields[0]
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.form.loading = false
        })
    },

    storeSortOrder (key, event) {
      const model = cloneDeep(this.model)
      const groupItems = filter(model.parts, { extra_data: { assembly_key: key } })
      const modelItemsDiff = difference(model.parts, groupItems)
      if (groupItems && groupItems.length > 0) {
        const movedItem = cloneDeep(groupItems[event.moved.oldIndex])
        groupItems.splice(event.moved.oldIndex, 1)
        groupItems.splice(event.moved.newIndex, 0, movedItem)
        model.parts = [
          ...modelItemsDiff,
          ...(sortBy(map(groupItems, (part, index) => {
            part.pivot.order = index
            return part
          }), (item) => { return item.pivot.order }))
        ]
      }
      this.$emit('update:model', model)
    },

    sortGroup (key, order = 'name') {
      const model = cloneDeep(this.model)
      const groupItems = filter(model.parts, { extra_data: { assembly_key: key } })
      const modelItemsDiff = difference(model.parts, groupItems)
      if (groupItems && groupItems.length > 0) {
        model.parts = [
          ...modelItemsDiff,
          ...(map(sortBy(groupItems, order), (part, index) => {
            part.pivot.order = index
            return part
          }))
        ]
      }
      this.$emit('update:model', model)
      this.dropdown.splice(findIndex(key), 1)
    },

    addModels () {
      const model = cloneDeep(this.model)
      model.parts = [
        ...model.parts,
        ...map(cloneDeep(this.form.new), (part, index) => {
          this.$set(part, 'pivot', { order: model.parts.length + (index + 1) })
          return part
        })
      ]
      this.$emit('update:model', model)
      this.form.new = []
      this.form.modal = false
    },

    removeModel (id) {
      const model = cloneDeep(this.model)
      model.parts[findIndex(model.parts, { id })] = null
      model.parts = compact(model.parts)
      this.$emit('update:model', model)
    },

    searchModels () {
      this.getModels()
    },

    toggleDropdown (key) {
      if (this.dropdown.includes(key)) {
        this.dropdown.splice(findIndex(key), 1)
      } else {
        this.dropdown.push(key)
      }
    }
  }
}
</script>
